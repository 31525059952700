<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-img7.jpg" alt="image"></a>
                    </div>

                    <div class="single-blog-item">
                        <div class="blog-content">
                            <a routerLink="/blog-details"><h3>Boost your Startup agency with Digital Agency</h3></a>
                            <p>Lorem ipsum dolor sit amconsectetur adipiscing elit, sed do eiusmodor.</p>
                        </div>

                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="flaticon-appointment"></i> 03 January 2019</a></li>
                            <li><i class="flaticon-user"></i> Janathon Doe</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-img8.jpg" alt="image"></a>
                    </div>

                    <div class="single-blog-item">
                        <div class="blog-content">
                            <a routerLink="/blog-details"><h3>Get the support from the main appointment center</h3></a>
                            <p>Lorem ipsum dolor sit amconsectetur adipiscing elit, sed do eiusmodor.</p>
                        </div>

                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="flaticon-appointment"></i> 03 January 2019</a></li>
                            <li><i class="flaticon-user"></i> Janathon Doe</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-img9.jpg" alt="image"></a>
                    </div>

                    <div class="single-blog-item">
                        <div class="blog-content">
                            <a routerLink="/blog-details"><h3>Startup is first priority to go ahead Digital Agency</h3></a>
                            <p>Lorem ipsum dolor sit amconsectetur adipiscing elit, sed do eiusmodor.</p>
                        </div>

                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="flaticon-appointment"></i> 03 January 2019</a></li>
                            <li><i class="flaticon-user"></i> Janathon Doe</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>