<app-navbar-one></app-navbar-one>

<!-- banner -->
<section class="BannerSec pb-50">
    <div class="">
        <div class="banner-Slider owl-carousel owl-theme">
            <div class="banner-item">
                <img src="assets/img/YI-img/Banner1.png" alt="">
            </div>
            <!-- <div class="banner-item">
                <img src="assets/img/YI-img/banner2.png" alt="">
            </div> -->
            <!-- <div class="banner-item">
                <img src="assets/img/YI-img/banner3.png" alt="">
            </div> -->
            <!-- <div class="banner-item">
                <img src="assets/img/YI-img/banner4.png" alt="">
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="BannerSec pb-50">
    <div class="container">
        <div class="row BannerContent">
            <div class="Bannerleft">
                <form id="msform" class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                    <div class="tab products-details-tab form_Tab">
                        <ul class="tabs">
                            <li>
                                <a href="javascript:void(0)" class="leftBtn">
                                    <div class="dot"></div>
                                    <span class="Get">get</span>
                                    <h6 class="Register">Register</h6>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class="">
                                    <div class="dot"></div>
                                    <span>sell</span>
                                    <h6>agri input</h6>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class="">
                                    <div class="dot"></div>
                                    <span>purchase</span>
                                    <h6>agri produce</h6>
                                </a>
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="products-details-tab-content">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/user.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Full Name" aria-label="Username" aria-describedby="basic-addon1">
                                      </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/email.png" alt="" class="input_Icon"></span>
                                        <input type="email" class="form-control" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/phone-call.png" alt="" class="input_Icon"></span>
                                        <input type="number" class="form-control" placeholder="Phone Number" aria-label="Username" aria-describedby="basic-addon1" min="0">
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text pr-3" for="inputGroupSelect01"><img src="assets/img/YI-img/application.png" alt="" class="input_Icon"></label>
                                        <select class="form-select" id="inputGroupSelect01">
                                          <option selected>Select Category</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/search-input.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Enter Pincode Number" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                    <label for="vehicle1" class="ml-2 form_Check">I here by authorise BioVeda to contact me for registering with BioVeda</label><br>
                                    <button class="Register_Btn">Register Now</button>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="products-details-tab-content">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/user.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                                      </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/email.png" alt="" class="input_Icon"></span>
                                        <input type="email" class="form-control" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/phone-call.png" alt="" class="input_Icon"></span>
                                        <input type="number" class="form-control" placeholder="Phone Number Address" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text pr-3" for="inputGroupSelect01"><img src="assets/img/YI-img/application.png" alt="" class="input_Icon"></label>
                                        <select class="form-select" id="inputGroupSelect01">
                                          <option selected>Choose...</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/search-input.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Pincode Number" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                    <label for="vehicle1" class="ml-2 form_Check">I here by authorise BioVeda to contact
                                        me for registering with BioVeda</label><br>
                                    <button class="Register_Btn">Register Now</button>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="products-details-tab-content">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/user.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/email.png" alt="" class="input_Icon"></span>
                                        <input type="email" class="form-control" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/phone-call.png" alt="" class="input_Icon"></span>
                                        <input type="number" class="form-control" placeholder="Phone Number Address" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text pr-3" for="inputGroupSelect01"><img src="assets/img/YI-img/application.png" alt="" class="input_Icon"></label>
                                        <select class="form-select" id="inputGroupSelect01">
                                          <option selected>Choose...</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><img src="assets/img/YI-img/search-input.png" alt="" class="input_Icon"></span>
                                        <input type="text" class="form-control" placeholder="Pincode Number" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                    <label for="vehicle1" class="ml-2 form_Check">I here by authorise BioVeda to contact
                                        me for registering with BioVeda</label><br>
                                    <button class="Register_Btn">Register Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="Bannerright">
                <div class="bannerCon-left">
                    <h1 class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">All the agri product</h1>
                    <h1 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp; margin-left: 3px;">you need, in one place</h1>
                    <p class="wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="2s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInUp; margin-left: 3px;">Find the best high-quality product</p>
                    <p class="wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="2.5s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInUp; margin-left: 3px;">for Selling and purchasing</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- banner end -->

<!-- -------------why choose------------- -->
<section class="why_Choose">
    <div class="container">
        <div class="text-center">
            <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Why BioVeda</h2>
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 49%;
            margin: 0 auto;">Whether you buy agricultural inputs or sell agricultural products, BioVeda offer you best service.</p>
        </div>

        <div class="tabContentBox">
            <div class="container">
                <div class="tab presence-tab">
                    <div class="row">
                        <div class="col-lg-4 order-lg-1 order-2 wow fadeInUp animated" data-wow-duration="1.5s"
                            data-wow-delay="1.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="tab_content mt-5">
                                <div class="tabs_item">
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/thumb.png" alt="">
                                        <div>
                                            <h6>Genuine Quality Product</h6>
                                            <p>Access best Agri input supplies from a range of Brands</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/cross.png" alt="">
                                        <div>
                                            <h6>Ready to sell your crop</h6>
                                            <p>Obtain the best rates for your agri produce with BioVeda</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/STAR-circle.png" alt="">
                                        <div>
                                            <h6>launching BV Quick</h6>
                                            <p>Your online kirana store</p>
                                        </div>
                                    </div>
                                    <!-- <div class="why_Left_content">
                                        <img src="assets/img/YI-img/rupee.png" alt="">
                                        <div>
                                            <h6>Veda support</h6>
                                            <p>Get an instant loan for purchasing Agri inputs</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="tabs_item">
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/thumb.png" alt="">
                                        <div>
                                            <h6>Genuine Quality Product</h6>
                                            <p>Access best Agri input supplies from a range of Brands</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/cross.png" alt="">
                                        <div>
                                            <h6>Ready to sell your crop</h6>
                                            <p>Obtain the best rates for your agri produce with BioVeda</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/STAR-circle.png" alt="">
                                        <div>
                                            <h6>launching BV Quick</h6>
                                            <p>Your online kirana store</p>
                                        </div>
                                    </div>
                                    <!-- <div class="why_Left_content">
                                                                        <img src="assets/img/YI-img/rupee.png" alt="">
                                                                        <div>
                                                                            <h6>Veda support</h6>
                                                                            <p>Get an instant loan for purchasing Agri inputs</p>
                                                                        </div>
                                                                    </div> -->
                                </div>
                                <div class="tabs_item">
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/thumb.png" alt="">
                                        <div>
                                            <h6>Genuine Quality Product</h6>
                                            <p>Access best Agri input supplies from a range of Brands</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/cross.png" alt="">
                                        <div>
                                            <h6>Ready to sell your crop</h6>
                                            <p>Obtain the best rates for your agri produce with BioVeda</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/STAR-circle.png" alt="">
                                        <div>
                                            <h6>launching BV Quick</h6>
                                            <p>Your online kirana store</p>
                                        </div>
                                    </div>
                                    <!-- <div class="why_Left_content">
                                                                        <img src="assets/img/YI-img/rupee.png" alt="">
                                                                        <div>
                                                                            <h6>Veda support</h6>
                                                                            <p>Get an instant loan for purchasing Agri inputs</p>
                                                                        </div>
                                                                    </div> -->
                                </div>
                                <div class="tabs_item">
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/thumb.png" alt="">
                                        <div>
                                            <h6>Genuine Quality Product</h6>
                                            <p>Access best Agri input supplies from a range of Brands</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/cross.png" alt="">
                                        <div>
                                            <h6>Ready to sell your crop</h6>
                                            <p>Obtain the best rates for your agri produce with BioVeda</p>
                                        </div>
                                    </div>
                                    <div class="why_Left_content">
                                        <img src="assets/img/YI-img/STAR-circle.png" alt="">
                                        <div>
                                            <h6>launching BV Quick</h6>
                                            <p>Your online kirana store</p>
                                        </div>
                                    </div>
                                    <!-- <div class="why_Left_content">
                                                                        <img src="assets/img/YI-img/rupee.png" alt="">
                                                                        <div>
                                                                            <h6>Veda support</h6>
                                                                            <p>Get an instant loan for purchasing Agri inputs</p>
                                                                        </div>
                                                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 order-lg-2 order-1 whyChooseBg">
                            <!-- <ul class="tabs row">
                                <li class="col-md-6 wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    <a href="javascript:void(0)" class="">
                                        <div class="">
                                            <img class="Tab_Img Tab_Img1" src="assets/img/YI-img/tab1.png" alt="">
                                            <div class="tab_Img_Section">
                                                <img src="assets/img/YI-img/register.png" alt="">
                                                <p>register</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="col-md-6 wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    <a href="javascript:void(0)" class="">
                                        <div class="">
                                            <img class="margin_Top Tab_Img Tab_Img2" src="assets/img/YI-img/tab2.png"
                                                alt="">
                                            <div class="tab_Img_Section margin_Top">
                                                <img src="assets/img/YI-img/sell-input.png" alt="">
                                                <p>sell agri input</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="col-md-6 mt-3 wow fadeInUp animated" data-wow-duration="1.5s"
                                    data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    <a href="javascript:void(0)" class="">
                                        <div class="">
                                            <img class="Tab_Img Tab_Img1" src="assets/img/YI-img/Bankloan-new.png"
                                                alt="">
                                            <div class="tab_Img_Section">
                                                <img src="assets/img/YI-img/sell-input.png" alt="">
                                                <p>bank loan</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="col-md-6 mt-3 wow fadeInUp animated" data-wow-duration="1.5s"
                                    data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    <a href="javascript:void(0)" class="">
                                        <div class="">
                                            <img class="Tab_Img Tab_Img2"
                                                src="assets/img/YI-img/purchase-agri-input.png" alt="">
                                            <div class="tab_Img_Section">
                                                <img src="assets/img/YI-img/purchase.png" alt="">
                                                <p>purchase agri input</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!---------------why choose end--------------->

<!----------- Ready to sell--------------->
<section class="sell_section">
    <div class="container">
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="sell-bg">
                        <div class="">
                            <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                                src="../../../../assets/img/YI-img/ready-to-sell-left-part1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="sell_section_Text">
                        <div class="sell_section_Img">
                            <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                                src="../../../../assets/img/Bioveda-img/oldkisan.png" alt="">
                        </div>
                        <h4 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.2s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Ready to sell
                            your crop</h4>
                        <p class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2.5s"
                            style="visibility: visible; animation-delay: 0.3s;line-height: 19.4px; animation-name: fadeInUp;width: 80%; margin-left: auto;">
                            Selling at BioVeda is simple. Just book your appointment at our BioVeda Center</p>
                        <div class="produceNowBtn">
                            <a class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="3s"
                                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                                href="/sell-your-produce">SELL
                                AGRI PRODUCE NOW</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="sell_section">
    <div class="container">
        <div class="sell_section_Content">
            <div class="Sell_Inner_Content">
                <div class="sell_section_Img">
                    <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                        src="../../../../assets/img/Bioveda-img/oldkisan.png" alt="">
                </div>
                <div class="sell_section_Text">
                    <h4 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.2s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Ready-to-sell your
                        crop</h4>
                    <p class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Selling at BioVeda
                        is simple. Just book your appointment at our BioVeda Center</p>
                    <div class="produceNowBtn">
                        <a class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="3s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                            href="javascript:void(0)">SELL AGRI PRODUCE NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!----------- Ready to sell end ------------- -->

<!-- Explore the handpicked collection on demand -->
<section class="collection_section">
    <div class="container">
        <div class="text-center">
            <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Explore the handpicked
                collection on demand</h2>
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Try BioVeda for obtaining
                The best range of quality products.</p>
        </div>
        <div class="row mt-4">
            <div class="col-md-3">
                <a href="javascript:void(0)" class="seeds wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay="3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h5>SEEDS</h5>
                </a>
            </div>
            <div class="col-md-3">
                <a href="javascript:void(0)" class="seeds fertiliser wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay="3.5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h5>Chemicals</h5>
                </a>
            </div>
            <div class="col-md-3">
                <a href="javascript:void(0)" class="seeds combokit wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay="4s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h5>combo-kit</h5>
                </a>
            </div>
            <div class="col-md-3">
                <a href="javascript:void(0)" class="seeds agriTool wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay="4.5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h5>agri Tools</h5>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- Explore the handpicked collection on demand end -->

<!-- Explore beyond the local - Go Global" -->
<section class="product_section wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="3.5s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="client-wrap owl-carousel owl-theme">
                    <div class="banner-item">
                        <div class="product_Content">
                            <div class="">
                                <div class=" d-flex align-items-center ">
                                    <div class="">
                                        <h4>Explore beyond the local - Go Global"</h4>
                                        <p>Explore our extensive library of seeds, Crop Protection Chemicals with a FREE
                                            ACCOUNT. Share with your friends to reap
                                            more benefits!!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-item">
                        <div class="product_Content">
                            <div class="">
                                <div class=" d-flex align-items-center ">
                                    <div class="">
                                        <h4>Explore beyond the local - Go Global"</h4>
                                        <p>Explore our extensive library of seeds, Crop Protection Chemicals with a FREE
                                            ACCOUNT. Share with your friends to reap
                                            more benefits!!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-item">
                        <div class="product_Content">
                            <div class="">
                                <div class=" d-flex align-items-center ">
                                    <div class="">
                                        <h4>Explore beyond the local - Go Global"</h4>
                                        <p>Explore our extensive library of seeds, Crop Protection Chemicals with a FREE
                                            ACCOUNT. Share with your friends to reap
                                            more benefits!!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <img src="assets/img/YI-img/goglobel.png" alt="">

            </div>
        </div>
    </div>
</section>
<!-- Explore beyond the local - Go Global" end -->

<!-- team Section -->
<section class="overlay_section">
    <div class="container">
        <div class="teamHeading">
            <div class="teamHeadingLeft">
                <h2>Join our Talented BioVeda Team</h2>
                <!-- <p>Explore our extensive library of Seeds, Agri input, Crop with free account you can Your all
                    collection and share with your friends</p> -->
                    <p class="w-100">If you are a greatest passionate and hungry for success, Bioveda is the place for YOU!</p>
            </div>
            <div class="teamHeadingRight">
                <a routerLink="/career" href="javascript:void(0)"><img class="jointeamImg"
                        src="assets/img/YI-img/joinourteam-icon.png" alt=""> Join Our Team <img class="NextImg"
                        src="assets/img/YI-img/next.png" alt=""></a>
            </div>
        </div>
        <div class="TeamSec activity-Slider owl-carousel owl-theme">
            <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img class="content-image" src="assets/img/YI-img/team.png">
                            <div class="text_bottom">
                                <h2>FOUNDER</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img class="teamshortImg" src="assets/img/YI-img/team.png" alt="">
                                    </div>
                                    <h5>Kaushik Dasgupta</h5>
                                    <span>BUSINESS DEV.SUPPLY CHAIN SALES</span>
                                    <div class="sub_cont">
                                        <p>SALES DISTRIBUTION SUPPLY CHAIN 20+ EXP.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img class="content-image" src="assets/img/YI-img/team2.png">
                            <div class="text_bottom">
                                <h2>FOUNDER</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img src="assets/img/YI-img/team2.png" alt="">
                                    </div>
                                    <h5>Mahua Hazra</h5>
                                    <!-- <span>MARKETING TRAININING MFI/NBFC ENAGEMENT</span> -->
                                    <div class="sub_cont">
                                        <p>Agent Management Marketing Channel Management and Training</p>
                                        <!-- <p>MARKETING BFSI FINANCE (Client Servicing) 22+ Exp.</p> -->
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="banner-item">
                <div class="TeamBlock ">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img class="content-image" src="assets/img/YI-img/team3.png">
                            <div class="text_bottom">
                                <h2>CORE TEAM</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img src="assets/img/YI-img/team3.png" alt="">
                                    </div>
                                    <h5>Dr. Alok Srivastava</h5>
                                    <span>CORE PRODUCT DEV.PROJECTS Consultant - Word Bank, JICA</span>
                                    <div class="sub_cont">
                                        <p>AGRI F$V DOMAIN EXPERT (Pan India/Global)30+ Exp.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img class="content-image" src="assets/img/YI-img/team4.png">
                            <div class="text_bottom">
                                <h2>CORE TEAM</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img src="assets/img/YI-img/team4.png" alt="">
                                    </div>
                                    <h5>Sunil Sihag</h5>
                                    <span>AGRI INPUT & ADVISORY MANAGEMENT</span>
                                    <div class="sub_cont">
                                        <p>AGRI INPUT AGRI OUTPUT EXPERT 23+ Exp.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img class="content-image" src="assets/img/YI-img/harshsir-photo.png">
                            <div class="text_bottom">
                                <h2>TECH. PARTNER</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img src="assets/img/YI-img/harshsir-photo.png" alt="">
                                    </div>
                                    <h5>Harsh Kumar</h5>
                                    <span>HEAD - TECHNOLOGY DEVELOPMENT</span>
                                    <div class="sub_cont">
                                        <p>TECHNOLOGY DEVELOPMENT EXPERT 15+ Exp.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <a>
                            <div class="content-overlay"></div>
                            <img src="assets/img/YI-img/logo_img.png" alt="">
                            <div class="text_bottom">
                                <h2>INVESTOR</h2>
                            </div>
                            <div class="content-details fadeIn-bottom">
                                <div class="card_inner_text">
                                    <div class="back_thum">
                                        <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                        <img src="assets/img/YI-img/logo_img.png" alt="">
                                    </div>
                                    <!-- <h5>Harsh Kumar</h5> -->
                                    <!-- <span>MENTOR & GUIDE FOR BUSINESS MANAGEMENT</span> -->
                                    <div class="sub_cont">
                                        <p>MENTOR & GUIDE FOR BUSINESS MANAGEMENT</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <div class="bg_thum">
                            <img src="assets/img/YI-img/logo_img.png" alt=""
                                style="width: -webkit-fill-available; height: 18rem; border-radius: 10px;">
                            <div class="text_bottom">
                                <h2>INVESTOR</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="teamHeading">
            <div class="teamHeadingLeft">
                <h2>Our Gallery</h2>
                <!-- <p>Explore our extensive library of Seeds, Fertiliser, Crop with free account you can Your all
                    collection and share with your friends</p> -->
            </div>
            <div class="teamHeadingRight">
                <a routerLink="/our-gallery" href="javascript:void(0)"><img class="showgalleryImg"
                        src="assets/img/YI-img/showgallery.png" alt="">Show All Images and Video<img class="NextImg"
                        src="assets/img/YI-img/next.png" alt=""></a>
            </div>
        </div>
        <!-- <div class="ourGallerySec">
            <img src="assets/img/YI-img/blog-1.jpeg" alt="">
            <img src="assets/img/YI-img/blog-1.jpeg" alt="">
            <img src="assets/img/YI-img/blog-1.jpeg" alt="">
            <img src="assets/img/YI-img/blog-1.jpeg" alt="">
            <img src="assets/img/YI-img/blog-1.jpeg" alt="">
        </div> -->
        <div class="aligner">
            <div class="ourGallerySec activity-Slider owl-carousel owl-theme mt-3">
                <div class="banner-item">
                    <a href="assets/img/YI-img/gallery1.jpeg" data-lightbox="gallery">
                        <img src="assets/img/YI-img/gallery1.jpeg" alt="">
                    </a>
                    <!-- <img src="assets/img/YI-img/gallery1.jpeg" alt=""> -->
                </div>
                <div class="banner-item">
                    <a href="assets/img/YI-img/gallery2.jpeg" data-lightbox="gallery">
                        <img src="assets/img/YI-img/gallery2.jpeg" alt="">
                    </a>
                    <!-- <img src="assets/img/YI-img/gallery2.jpeg" alt=""> -->
                </div>
                <div class="banner-item">
                    <a href="assets/img/YI-img/gallery3.jpeg" data-lightbox="gallery">
                        <img src="assets/img/YI-img/gallery3.jpeg" alt="">
                    </a>
                    <!-- <img src="assets/img/YI-img/gallery3.jpeg" alt=""> -->
                </div>
                <div class="banner-item">
                    <a href="assets/img/YI-img/gallery4.jpeg" data-lightbox="gallery">
                        <img src="assets/img/YI-img/gallery4.jpeg" alt="">
                    </a>
                    <!-- <img src="assets/img/YI-img/gallery4.jpeg" alt=""> -->
                </div>
                <div class="banner-item">
                    <a href="assets/img/YI-img/gallery5.jpeg" data-lightbox="gallery">
                        <img src="assets/img/YI-img/gallery5.jpeg" alt="">
                    </a>
                    <!-- <img src="assets/img/YI-img/gallery5.jpeg" alt=""> -->
                </div>
            </div>
        </div>
        <div class="ourGallerySec activity-Slider owl-carousel owl-theme mt-3">
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery6.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery6.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery6.jpeg" alt=""> -->
            </div>
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery7.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery7.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery7.jpeg" alt=""> -->
            </div>
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery8.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery8.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery8.jpeg" alt=""> -->
            </div>
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery9.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery9.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery9.jpeg" alt=""> -->
            </div>
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery10.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery10.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery10.jpeg" alt=""> -->
            </div>
            <div class="banner-item">
                <a href="assets/img/YI-img/gallery11.jpeg" data-lightbox="gallery">
                    <img src="assets/img/YI-img/gallery11.jpeg" alt="">
                </a>
                <!-- <img src="assets/img/YI-img/gallery11.jpeg" alt=""> -->
            </div>
        </div>
        <div class="row suppliers_section">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="banner-Slider owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 3rem;">
                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter.png" alt="">
                    </div>

                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter2.png" alt="">
                    </div>

                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter3.png" alt="">
                    </div>
                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter4.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="footerText wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h2>Access best Agri input supplies from a range of suppliers</h2>
                </div>
                <div class="newsletter suppliersCon wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <!-- <p>Please 10-digit mobile number to get mobile application link</p> -->
                    <p>Download Your Bioveda Application</p>
                    <!-- <div class="input-group mb-3">
                        <span class="input-group-text">+91</span>
                        <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                            placeholder="Enter your number here">
                        <button type="button" class="input-group-text subscribeBtn"><img
                                src="assets/img/YI-img/paper-plane.png" alt=""></button>
                    </div> -->
                </div>
                <div class="app_Btn app_button">
                    <!-- <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Download Your
                        Bioveda Application</h5> -->
                    <div><a target="_blank" href="http://play.google.com/store/apps/details?id=com.troology.bioveda&hl=en">
                        <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                            src="assets/img/YI-img/googleplay.png" alt="">
                            </a>
                        <!-- <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                            src="assets/img/YI-img/App-Store.png" alt=""> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- team Section end -->

<!-- Key statistics -->
<section class="Key_statistics">
    <div class="container">
        <div class="text-center">
            <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Key statistics</h2>
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Collaborative approach
                allows us to create impact at a massive scale</p>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3">
                <img class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                    src="assets/img/YI-img/key-statistics.png" alt="">
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box1 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="1.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>01</h3>
                                <p>States</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box2 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="2s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>5</h3>
                                <p>District Operational</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box3 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="2.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>10,000+</h3>
                                <p>BV Prime Farmers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box4 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="1.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>800+</h3>
                                <p>Kisan Mitras</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box5 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="2s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>4,000+</h3>
                                <p>Registered Farmers</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="Key_statistics_Box Key_statistics_Box6 wow fadeInUp animated"
                            data-wow-duration="1.5s" data-wow-delay="2.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <!-- <img src="assets/img/YI-img/leaf.png" alt=""> -->
                            <div>
                                <h3>1 Lakh  <span>Acres</span></h3>
                                <p>Land Covered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Key statistics end -->

<!-- testimonial_Section -->
<!-- <section class="testimonial_Section wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <div class="container">
            <h2>Testimonials</h2>
            <p>What our customer say about Bioveda</p>
        </div>
        <div class="saying-slides owl-carousel owl-theme">
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- testimonial_Section end -->

<!-- media coverage/Our Partner -->
<!-- <section class="MediaCoverage_OurPartner">
    <div class="container">
       <div class="row">
        <div class="col-lg-6 borderR">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Media Coverage</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Media Coverage</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Partner</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Partner</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>
</section> -->
<!-- media coverage/Our Partner end-->

<!-- partner -->
<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- partner end-->