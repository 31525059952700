<app-navbar-one></app-navbar-one>
<!-- 
<div class="page-title-area contact_Page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content contact_Page_content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="fa fa-map-marker"></i>

                    <div class="content-title">
                        <h3>Address</h3>
                        <p>32 st lorem ipsum Road, consectetur adipisicing, 3004 India</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="fa fa-envelope"></i>

                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:hello@example.com">hello@example.com</a>
                        <a href="mailto:info@example.com">info@example.com</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="fa fa-phone"></i>

                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:+123(456)123">+123(456)123</a>
                        <a href="tel:+123(456)123">+123(456)123</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="mainHeading">Drop us a message for any query</h2>
            <p class="mainPara">If you have an idea, we would love to hear about it.</p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" placeholder="Phone">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="subject" id="subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>
                    
                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-lg-12">
                        <button type="submit" class="default-btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section> -->

<div class="page-title-area buy_product_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>Helpline</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Helpline</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="coming-page">
        <img style="width: 100%;" src="../../../../assets/img/YI-img/commingsoon.png" alt="">
    </div>
</section>

<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>