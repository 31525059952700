<footer class="footer-section">
    <div class="container">
        <!-- <h4 class="quickLinks">+ Quick Links <span>- more BioVeda</span></h4> -->
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="footerText">
                    <img class="BioLogo" src="assets/img/YI-img/BoiveadLogo.png" alt="">
                    <p>From farm to the fork, crops have a long journey to undertake. At BioVeda, we challenge
                        ourselves every day to bring digital efficiency to this journey.</p>
                </div>
                <div class="newsletter FooterNewsletter">
                    <label for="">sign up for our newsletter</label>
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Enter your Email Address"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2">Subscribe</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <h3>QUICK LINKS</h3> -->
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">About Bioveda</a></li>
                        <!-- <li><a routerLink="/">Steps </a></li> -->
                        <li><a routerLink="/">BV Quick </a></li>
                        <li><a routerLink="/">Press Media</a></li>
                        <li><a routerLink="/">FAQs</a></li>
                        <li><a routerLink="/">Terms of Use</a></li>
                        <!-- <li><a routerLink="/">SAAS Agreement</a></li> -->
                        <li><a routerLink="/">Agri Tools</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Veda Insights</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <h3>BIOVEDA INSIGHTS</h3> -->

                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Why BioVeda</a></li>
                        <!-- <li><a routerLink="/">People Philosophy</a></li> -->
                        <li><a routerLink="/">Our Invester</a></li>
                        <li><a routerLink="/career">Career Opportunities</a></li>
                        <li><a routerLink="/">Seeds</a></li>
                        <!-- <li><a routerLink="/">Fertiliser</a></li> -->
                        <li><a routerLink="/">Pesticides</a></li>
                        <li><a routerLink="/">Sell Produce</a></li>
                        <li><a routerLink="/">Purchase Product</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="certifications_SocialIcon">
                <div class="">
                    <!-- <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Disclaimer</a></li>
                    </ul>
                    <p><i class="far fa-copyright"></i> 2021-22 BioVeda. All Rights Reserved</p> -->
                    <div class="app_Btn">
                        <h5>security certifications</h5>
                        <div><a target="_blank" href="http://play.google.com/store/apps/details?id=com.troology.bioveda&hl=en">
                            <img src="assets/img/YI-img/googleplay.png" alt="">
                            </a>
                            <!-- <img src="assets/img/YI-img/App-Store.png" alt=""> -->
                        </div>
                    </div>

                </div>
                <div class="Social_Icon">
                    <h5>Follow Us</h5>
                    <ul class="">
                        <li><a href="javascript:void(0)" class="facebook" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript:void(0)" class="twitter" target="_blank"><i
                                    class="fab fa-twitter"></i></a></li>
                        <li><a href="javascript:void(0)" class="linkedin" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="javascript:void(0)" class="instagram" target="_blank"><i
                                    class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="CopyRightReservedSec">
        <div class="container">
            <div class="CopyRightReservedLeft_Right">
                <p class=""><i class="far fa-copyright"></i> 2024 BioVeda. All Rights Reserved
                </p>
                <div class="terms-conditions">
                    <ul>
                        <li>
                            <a href="/privacypolicy">Privacy Policy</a> |
                            <a href="/termsconditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Ervy</h3>
                        <img src="assets/img/about.jpg" alt="">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolore saepe exercitationem
                            alias unde, blanditiis non error</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>
                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>121 King St, VIC 3000, Australia</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>hello@ervy.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+098-123-23455</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li>
                                <a href="#" target="_blank"><i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><i class="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>