<app-navbar-one></app-navbar-one>

<div class="page-title-area buy_product_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>Buy agri product</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Buy Agri Product</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="buy-agri">
    <div class="container">
        <div class="buy-body">
            <h3>Buy Agri Products at the best prices and from convenience from the phone</h3>
            <h6>7000+ Farmers believe in us</h6>
            <div class="buy-product-thum">
                <img src="../../../../assets/img/YI-img/buyagriinput.png" alt="">
            </div>
        </div>
    </div>
</section>

<section class="buy-slide">
    <div class="container">
        <div class="ourGallerySec product-Slider owl-carousel owl-theme mt-3">
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery1.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery2.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery3.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery4.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery5.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery4.jpeg" alt="">
            </div>
            <div class="banner-item">
                <img src="assets/img/YI-img/gallery5.jpeg" alt="">
            </div>
        </div>
    </div>
</section>

<section class="seed-section">
    <div class="container">
        <div class="seed">
            <div class="seed-cont">
                <h5>SEEDS</h5>
                <p>Vegetable Seeds Shop hundreds of heirlooms, organic, non-GMO Vegetable seeds and plants to grow in
                    your garden.</p>
            </div>
            <div class="search-seed">
                <div class="input-group mb-3">
                    <input type="email" placeholder="Search by name, category or Keyword"
                        aria-label="Recipient's username" aria-describedby="button-addon2" class="form-control">
                    <button type="button" id="button-addon2" class="btn btn-outline-success">logo</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="product-list-sec">
    <div class="container">
        <div class="product-list">
            <div class="row">
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="product-item">
                        <div class="product-img">
                            <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Product Name</h6>
                            <h6>000/-</h6>
                            <a type="button">Call to action button</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a type="button" class="btn load-more mt-4 mb-4">LOAD MORE</a>
    </div>
</section>


<section>
    <div class="container">
        <div class="tody-arrival">
            <div class="col-md-12">
                <div class="arrival-title">
                    <h5>TODAY ARRIVAL</h5>
                </div>

                <div class="galary owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                            <div class="product-data">
                                <h6>Product Name</h6>
                                <h6>000/-</h6>
                                <a type="button">Call to action button</a>
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                            <div class="product-data">
                                <h6>Product Name</h6>
                                <h6>000/-</h6>
                                <a type="button">Call to action button</a>
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                            <div class="product-data">
                                <h6>Product Name</h6>
                                <h6>000/-</h6>
                                <a type="button">Call to action button</a>
                            </div>
                        </div>
                    </div>
                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                            <div class="product-data">
                                <h6>Product Name</h6>
                                <h6>000/-</h6>
                                <a type="button">Call to action button</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-5">
                <div class="arrival-title">
                    <h5>BRANDS</h5>
                </div>

                <div class="galary owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-data brand">
                                <h6>Brands Logo 1</h6>
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-data brand">
                                <h6>Brands Logo 1</h6>
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-data brand">
                                <h6>Brands Logo 1</h6>
                            </div>
                        </div>
                    </div>
                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-data brand">
                                <h6>Brands Logo 1</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-5">
                <div class="arrival-title">
                    <h5>EXCLUSIVE</h5>
                </div>

                <div class="exclusive owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="banner-item">
                        <div class="product-item">
                            <div class="product-img">
                                <img src="../../../../assets/img/YI-img/blog-2.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row suppliers_section">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="banner-Slider owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 3rem;">
                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter.png" alt="">
                    </div>

                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter2.png" alt="">
                    </div>

                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter3.png" alt="">
                    </div>
                    <div class="banner-item">
                        <img class="why_right_Img" style="width: 85%; float: right;"
                            src="assets/img/YI-img/newsletter4.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="footerText wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <h2>Access best Agri input supplies from a range of suppliers</h2>
                </div>
                <div class="newsletter suppliersCon wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                    <p>Please 10-digit mobile number to get mobile application link</p>
                    <div class="input-group mb-3">
                        <span class="input-group-text">+91</span>
                        <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                            placeholder="Enter your number here">
                        <button type="button" class="input-group-text subscribeBtn"><img
                                src="assets/img/YI-img/paper-plane.png" alt=""></button>
                    </div>
                </div>
                <div class="app_Btn app_button">
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Download Your
                        Bioveda Application</h5>
                    <div>
                        <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                            src="assets/img/YI-img/googleplay.png" alt="">
                        <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                            src="assets/img/YI-img/App-Store.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <section>
    <div class="container">
        <div class="aligner">
            <div class="container">
                <div class="owl-carousel owl-theme">
                    <div class="item">
                        <a href="http://via.placeholder.com/200x200" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        2
                        <a href="http://via.placeholder.com/200x200" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/300x300" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/400x400" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/500x500" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/600x600" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/700x700" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/800x800" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/900x900" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="http://via.placeholder.com/1000x1000" data-lightbox="gallery">
                            <img src="http://via.placeholder.com/200x200" alt="">
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section> -->