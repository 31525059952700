<app-navbar-one></app-navbar-one>
<div class="page-title-area buy_product_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>E-mandi</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>E-Mandi</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="coming-page">
        <img style="width: 100%;" src="../../../../assets/img/YI-img/commingsoon.png" alt="">
    </div>
</section>

<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>