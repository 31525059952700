<app-navbar-one></app-navbar-one>

<div class="page-title-area about_Section privacyPolicy_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>Terms and Conditions</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms_conditions ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="terms_condition_Sec">
                    <div class="">
                        <!-- <h1>Terms and Conditions</h1> -->
                        <!-- <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Effective
                            date:
                            30th September, 2022</p>
                        <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Last updated:
                            3rd
                            October, 2022
                        </p> -->
                    </div>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px; margin-top: 15px;">
                            BIOVEDA AGRO VENTURES PRIVATE LIMITED Mobile Application is an agricultural service provided
                            by
                            the application.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            It is provided as is and cannot be modified on user’s request.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The mobile application, logo, logic, code, algorithm is the sole property of BIOVEDA AGRO
                            VENTURES PRIVATE LIMITED. Any duplication, copying, unauthorized distribution, modification,
                            reverse engineering of the app to obtain the formula or recreate a similar logic is strictly
                            prohibited.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Service tax or other taxes are applicable as per the law
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The content provided in the application is purely for farmer’s reference and does not
                            provide
                            any warranty or guarantee for affecting directly or indirectly to the crop yield.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Agricultural yield management depends on various inputs and environmental factors and it
                            will be
                            individual risk and responsibility to utilize information provided by the app.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The company is not liable for any issue loss or damage or consequences caused by the use of
                            suggested products or dosage through the app.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            End users are asked to check with other agricultural experts before adopting any process or
                            product.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Customers should take analysis provided by the app only as a guidance and seek the advice of
                            any
                            other agricultural expert before any application of fertilizer or agricultural planning.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Since BIOVEDA does not manufacture any products suggested within the app it does not take
                            any
                            responsibility on usage of suggested procedures and services. BIOVEDA app is not responsible
                            for
                            any issues arising out of the use of the app.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The methods and solutions used in app is governed by BIOVEDA and its team this method is
                            practiced by BIOVEDA on its own field with controlled process; the results and effects vary
                            according to geographical and other factors.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Internal factors include but not limited to physiological, genetical, etc. External factors:
                            including but not limited to environmental, physical handling, etc. Hence the method in the
                            application may vary user to user. So it is at the sole discretion of the user or farmer to
                            use
                            the suggestions mentioned in the app.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            As agriculture production depends on various parameters and various important inputs
                            practiced
                            by farmers therefore considering sole usage of suggested dose will be the responsibility of
                            user
                            and its effect on crop yield.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We accept only advance payment for subscription and will not be refunded in any case.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            All legal liabilities will be subject to the GURUGRAM, HARAYNA, India jurisdiction.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            All legal liabilities will be subject to the GURUGRAM, HARAYNA, India jurisdiction.
                        </li>
                    </ul>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Payment Terms and Conditions
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Once a User has accepted these Terms and Conditions, he/ she may register and avail the
                            Services.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            In these Terms and Conditions, the term “Charge Back” shall mean, approved and settled
                            credit
                            card or net banking purchase transaction(s) which are at any time refused, debited or
                            charged
                            back to merchant account (and shall also include similar debits to Payment Service
                            Provider’s
                            accounts, if any) by the acquiring bank or credit card company for any reason whatsoever,
                            together with the bank fees, penalties and other charges incidental thereto.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Server Slow Down/Session Timeout: In case the App or Payment Service Provider’s webpage,
                            that is
                            linked to the App, is experiencing any server related issues like ‘slow down’ or ‘failure’
                            or
                            ‘session timeout’, the User shall, before initiating the second payment, check whether
                            his/her
                            Bank Account has been debited or not and accordingly resort to one of the following options:
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    In case the Bank Account appears to be debited, ensure that he/ she does not make
                                    the
                                    payment twice and immediately thereafter contact the Merchant /Company via e-mail or
                                    any
                                    other mode of contact as provided by the Merchant /Company to confirm payment.
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    In case the Bank Account is not debited, the User may initiate a fresh transaction
                                    to
                                    make payment.
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    However, the User agrees that under no circumstances the Payment Gateway Service
                                    Provider shall be held responsible for such fraudulent /duplicate transactions and
                                    hence
                                    no claims should be raised to Payment Gateway Service Provider. No communication
                                    received by the Payment Service Provider(s) in this regards shall be entertained by
                                    the
                                    Payment Service Provider(s).
                                </li>
                            </ul>
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The Merchant / Company and the Payment Service Provider(s) assume no liability whatsoever
                            for
                            any monetary or other damage suffered by the User on account of:
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    The delay, failure, interruption, or corruption of any data or other information
                                    transmitted in connection with use of the Payment Gateway or Services in connection
                                    thereto; and/ or
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Any interruption or errors in the operation of the Payment Gateway.
                                </li>
                            </ul>
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The User agrees, understands and confirms that his/ her personal data including without
                            limitation details relating to debit card/ credit card transmitted over the Internet may be
                            susceptible to misuse, hacking, theft and/ or fraud and that the Merchant / Company or the
                            Payment Service Provider(s) have no control over such matters.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Although all reasonable care has been taken towards guarding against unauthorized use of any
                            information transmitted by the User, the Merchant / Company does not represent or guarantee
                            that
                            the use of the Services provided by/ through it will not result in theft and/or unauthorized
                            use
                            of data over the Internet.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The Merchant / Company, the Payment Service Provider(s) and its affiliates and associates
                            shall
                            not be liable, at any time, for any failure of performance, error, omission, interruption,
                            deletion, defect, delay in operation or transmission, computer virus, communications line
                            failure, theft or destruction or unauthorized access to, alteration of, or use of
                            information
                            contained on the App.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The User will be required to login his/ her own Mobile no & OTP based, created by the user
                            after
                            registering on the App / in order to register and/ or use the Services /Products provided by
                            Merchant / Company on the App. By accepting these Terms and Conditions the User aggress that
                            his/ her Mobile no & OTP based are very important pieces of information and it shall be the
                            User’s own responsibility to keep them secure and confidential. In furtherance hereof, the
                            User
                            agrees to;
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Keep his/ her Mobile no & OTP strictly confidential.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Debit/Credit Card, Bank Account Details
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The User agrees that the debit/credit card details provided by him/ her for use of the
                            aforesaid
                            Service(s) must be correct and accurate and that the User shall not use a debit/ credit
                            card,
                            that is not lawfully owned by him/ her or the use of which is not authorized by the lawful
                            owner
                            thereof. The User further agrees and undertakes to provide correct and valid debit/credit
                            card
                            details.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            The User may make a payment Merchant / Company by using a debit/credit card or through
                            online
                            banking account. The User warrants, agrees and confirms that when he/she initiates a payment
                            transaction and/or issues an online payment instruction and provides his/ her card / bank
                            details:
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    The User is fully and lawfully entitled to use such credit / debit card, bank
                                    account
                                    for such transactions;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    The User is responsible to ensure that the card/ bank account details provided by
                                    him/
                                    her are accurate;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    The User is authorizing debit of the nominated card/ bank account for the purchase
                                    of
                                    products selected by such User along with the applicable shipping charges.
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    The User is responsible to ensure sufficient credit is available on the nominated
                                    card/
                                    bank account at the time of making the payment to permit the payment of the dues
                                    payable
                                    or the bill(s) selected by the User inclusive of the applicable Fee.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Payment Gateway Disclaimer
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">The Service is
                        provided in order to facilitate access to view and purchase products offered by BIOVEDA AGRO
                        VENTURES PRIVATE LIMITED. The Merchant / Company or the Payment Service Provider(s) do not make
                        any
                        representation of any kind, express or implied, as to the operation of the Payment Gateway other
                        than what is specified in the App for this purpose.
                        By accepting/ agreeing to these Terms and Conditions, the User expressly agrees that his/ her
                        use of
                        the aforesaid online payment Service is entirely at own risk and responsibility of the User.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Cancellation Policy
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Once the User will
                        be registered and paid the Fee through online Payment Gateway. He/She will not be able to cancel
                        the transaction in any circumstances. No Payment will be refunded to the User.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Payment Refund Policy for Online Payments Refund for Charge Back Transaction:
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">In the event there
                        is any claim for/ of charge back by the User for any reason whatsoever, such User shall
                        immediately approach the Merchant / Company with his/ her claim details and claim refund from
                        the Merchant / Company alone. Such refund (if any) shall be affected only by the Merchant /
                        Company via payment gateway or any other means as the Merchant / Company deems appropriate. No
                        claims for refund/ charge back shall be made by any User to the Payment Service Provider(s) and
                        in the event such claim is made it shall not be entertained. Any such request has to be sent to
                        <a style="color: #78b849;"
                            href="mailto:info@biovedaagroventures.com">info@biovedaagroventures.com</a> with the
                        registered email address used while placing the order.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        Refund for fraudulent/duplicate transaction(s):
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">The User shall
                        directly contact the Merchant / Company for any fraudulent transaction(s) on account of misuse
                        of Card/ Bank details by a fraudulent individual/party and such issues shall be suitably
                        addressed by the Merchant / Company alone in line with their policies and rules.
                    </p>
                </div>

                <div class="">
                    <h1 style="margin-top: 2rem;">Privacy Policy</h1>
                    <!-- <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Privacy Policy</h2> -->
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Effective date:
                        30th September, 2022</p>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Last updated: 3rd
                        October, 2022</p>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        GENERAL</h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            This App named Bioveda(“App / Application / apk”) is operated by BIOVEDA AGRO VENTURES
                            PRIVATE LIMITED. We are committed to protecting and respecting your privacy. We do collect
                            your personal information and process your personal data in accordance with the IT Act, 2000
                            (21 of 2000) and other national and state laws which relate the processing of personal data.
                            Please read the following carefully to understand our views and practices regarding your
                            personal data.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            We collect your personal information in order to provide and continually improve our
                            products and services.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            Our privacy policy is subject to change at any time without notice. To make sure you are
                            aware of any changes, please review this policy periodically.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            All partner firms and any third-party working with or for Us, and who have access to
                            personal information, will be expected to read and comply with this policy.
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        HOW WE COLLECT THE INFORMATION</h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            From you directly and through this App: We may collect information through the App when you
                            visit. The data we collect depends on the context of your interactions with our Website and
                            the App.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            Through business interaction: We may collect information through business interaction with
                            you or your employees.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            From other sources: We may receive information from other sources, such as public databases;
                            joint marketing partners; social media platforms; or other third parties such as:
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    Updated delivery and address information from our carriers or other third parties,
                                    which we use to correct our records and deliver your next purchase or communication
                                    more easily.
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="margin-bottom: 10px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                                    Information about your interactions with the products and services offered by our
                                    subsidiaries.
                                </li>
                            </ul>
                        </li>

                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        INFORMATION WE COLLECT</h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We collect information primarily to provide better services to all of our customers.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We collect the following information from you when you use or signup on our App: Name,
                            email, mobile no. billing address, and other agriculture details.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            When you visit our Site, some information is automatically collected. This may include
                            information such as the Operating Systems (OS) running on your device,
                            Internet Protocol (IP) address, access times, browser type, and language, and the website
                            you visited before our Site, phone state, permission to send SMS, permission to make phone
                            call, etc. We also collect information about how you use our products or services.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We automatically collect purchase or content use history, which we sometimes aggregate with
                            similar information from other customers to create features such as Best Seller, Top Rated,
                            etc.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We will retain your information as long as we require this to provide you with the goods and
                            services and for such period as mandated by the concerned laws.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            If you opt to receive marketing correspondence from us, subscribe to our mailing list or
                            newsletters, enter into any of our competitions or provide us with your details at
                            networking events, we may use your personal data for our legitimate interests in order to
                            provide you with details about our goods, services, business updates and events.
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        HOW WE USE INFORMATION
                    </h5>

                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We use the information we collect primarily to provide, maintain, protect and improve our
                            current products and services.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We use the information collected through this app as described in this policy and we may use
                            your information to:

                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Improve our services, app and how we operate our businesses;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Understand and enhance your experience using our app, products and services;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Personalize our products or services and make recommendations;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Process, manage, complete, and account for transactions;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Provide customer support and respond to your requests, comments, and inquiries;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Provide customer support and respond to your requests, comments, and inquiries;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Create and manage the online accounts you manage in our app;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Send you related information, including confirmations, invoices, technical notices,
                                    updates, security alerts and support and administrative messages;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Communicate with you about promotions, upcoming events and news about products and
                                    services;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    We may process your personal information without your knowledge or consent where
                                    required by applicable law or regulation for the purposes of verification of
                                    identity or for prevention, detection or investigation, including of cyber
                                    incidents, prosecution and punishment of offences;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    Protect, investigate and deter against fraudulent, unauthorized or illegal activity.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        DATA TRANSFER
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Information about our user is an important part of our business and we take due care to
                            protect the same.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We share your data with your consent or to complete any transaction or provide any product
                            or service you have requested or authorized. We also share data with our affiliates and
                            subsidiaries, with vendors working on our behalf.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We may employ other companies and individuals to perform functions on our behalf. The
                            functions include fulfilling orders for products or services, delivering packages, sending
                            postal mail and e-mail, removing repetitive information from customer lists, providing
                            marketing assistance, providing search results and links (including paid listings and
                            links), processing payments, transmitting content, scoring credit risk, and providing
                            customer service.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            These third-party service providers have access to personal information needed to perform
                            their functions but may not use it for other purposes. Further, they must process the
                            personal information in accordance with this Privacy Policy and as permitted by applicable
                            data protection laws.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We release account and other personal information when we believe is appropriate to comply
                            with the law, enforce or apply our conditions of use, and other agreements, protect the
                            rights, property or safety of Us, our users or others. This includes exchanging information
                            with other companies and organizations for fraud protection and credit risk reduction.
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        DATA SECURITY
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We take due care to protect customer data. Technical measures are in place to prevent
                            unauthorized or unlawful access to data and against accidental loss or destruction of, or
                            damage to, data. The employees who are dealing with the data have been trained to protect
                            the data from any illegal or unauthorized usage.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We work to protect the security of your information during transmission by using Secure
                            Sockets Locker (SSL) software, which encrypts information you input. SSL allows sensitive
                            information such as credit card numbers, UID’s and login credentials to be transmitted
                            securely.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling branded
                            credit cards from the major card schemes.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We maintain physical, electronic, and procedural safeguards in connection with the
                            collection, storage, and disclosure of personal customer information.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We take reasonable steps to help protect your personal information in an effort to prevent
                            the loss, misuse, and unauthorized access, disclosure alteration and destruction. It is your
                            responsibility to protect your user names and passwords to help prevent anyone from
                            accessing or abusing your accounts and services. You should not use or reuse the same
                            passwords you use with other accounts as your password for our services.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            It is important for you to protect against unauthorized access to your password and your
                            computers, devices, and applications. Be sure to sign off when you plan to change your
                            device.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Information you provide to us is shared on our secure servers. We have implemented
                            appropriate physical, technical and organizational measures designed to secure your
                            information against accidental loss and unauthorized access, use, alteration or disclosure.
                            In addition, we limit access to personal data to those employees, agents, contractors, and
                            other third parties that have a legitimate business need for such access.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            Information collected from you will be stored for such period as required to complete the
                            transaction entered into with you or such period as mandated under the applicable laws.
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        LINKS TO THIRD PARTY SITE/APPS
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our App may, from
                        time to time, contain links to and from other websites of third parties. Please note that if you
                        follow a link to any of these websites, such websites will apply different terms to the
                        collection and privacy of your personal data and we do not accept any responsibility or
                        liability for these policies. When you leave our app, we encourage you to read the privacy
                        policy of every website you visit.
                    </p>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        SHARING OF PERSONAL INFORMATION
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We do not share your personal data with third parties without your prior consent other than:
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            We do not share your personal data with third parties without your prior consent other than:
                            <ul>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    With third parties who work on our behalf provided such third parties adhere to the
                                    data protection principles set out in the IT Act, 2000 (21 of 2000) and other
                                    applicable legislation, or enter into a written agreement with Us requiring that the
                                    third party provide at least the same level of privacy protection as is required by
                                    such principles;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    To comply with laws or to respond to lawful requests and legal process;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    To protect the rights and property of Us, our agents, customers, and others
                                    including to enforce our agreements, policies and terms of use;
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    In an emergency, including to protect the personal safety of any person; and
                                </li>
                                <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                                    For the purpose of a business deal (or negotiation of a business deal) involving the
                                    sale or transfer of all or a part of our business or assets (business deals may
                                    include, for example, any merger, financing, acquisition, divestiture or bankruptcy
                                    transaction or proceeding).
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        CHILDREN
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our If you are
                        under 18, or the age of majority in the jurisdiction in which you reside, you may only use Our
                        app with the consent of your parent or legal guardian. In any case, we will not be liable for
                        any cause of action that arose due to noncompliance with this section.
                    </p>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        YOUR INFORMATION CHOICES AND CHANGES
                    </h5>
                    <ul>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            You can also make choices about the collection and processing of your data by us. You can
                            access your personal data and opt-out of certain services provided by us. In some cases,
                            your ability to control and access to your date will be subject to applicable laws.
                        </li>
                        <li class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;margin-bottom: 10px;">
                            You may opt-out of receiving promotional emails from us by following the instructions in
                            those emails. If you opt-out, we may still send you non-promotional emails, such as emails
                            about our ongoing business relationship. You may also send requests about you got
                            preferences, changes and deletions to your information including requests to opt-out of
                            sharing your personal information with third parties by sending an email to the email
                            address provided at the bottom of this document.
                        </li>
                    </ul>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        CHANGES TO THIS POLICY
                    </h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our we may change
                        this policy from time to time. If we make any changes to this policy, we will change the “Last
                        Updated” date above. You agree that your continued use of our services after such changes have
                        been published to our services will constitute your acceptance of such revised policy. If you
                        have any concern about privacy or grievances with us, please contact us with a thorough
                        description and we will try to resolve the issue for you. Contact Details:
                    </p>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">
                        BIOVEDA AGRO VENTURES PRIVATE LIMITED
                    </h5>

                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 1rem;">
                        REGD OFFICE: <p>TOWER 3-801, MALIBU TOWNE, SOHNA ROAD, SECTOR 47, GURUGRAM, Gurugram, Haryana,
                            122001, <br>
                            <a style="color: #78b849;"
                                href="mailto:info@biovedaagroventures.com">info@biovedaagroventures.com</a>, <a
                                style="color: #78b849;" href="tel:+91 8931021810">+ 91 8931021810</a>
                        </p>
                    </h5>
                </div>

                
            </div>
        </div>


    </div>
</section>

<!-- <section class="terms_conditions ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top:20px;">Bioveda Agro Ventures Pvt. Ltd. operates the Bioveda app, which provides the SERVICE.
                    </p>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top:20px;">This page is to inform app visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, “Bioveda”.
                    </p>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top:20px;">If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Terms and Conditions.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Information Collection and Uses</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Log Data</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">We want to inform you that whenever you visit our Service, we collect information that your app sends to us which is called Log Data. This Log Data may include information such as your device details, photos that you share, and other statistics. We use the best security solutions offered in the market.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Service Providers</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">We may employ third-party companies and individuals due to the following reasons:
                    </p>
                    <ul lass="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"  style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; padding-left: 0;">
                        <li style="list-style-type: none; font-size: 1rem; color: #5b5b5b;">
                            To facilitate our Service;
                        </li>
                        <li style="list-style-type: none; font-size: 1rem; color: #5b5b5b;">
                            To provide the Service on our behalf;
                        </li>
                        <li style="list-style-type: none; font-size: 1rem; color: #5b5b5b;">
                            To perform Service-related services;
                        </li> 
                        <li style="list-style-type: none; font-size: 1rem; color: #5b5b5b;">
                            To assist us in analyzing how our Service is used.
                        </li>  
                    </ul>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Security</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Links to Other Sites</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Terms and Conditions of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Changes to This Terms and Conditions</h5>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">We may update our Terms and Conditions from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.
                    </p>
                    <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 2rem;">Contact Us</h5>

                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at <a href="mailto:info@biovedaagroventures.com" style="color: #78b849;">info@biovedaagroventures.com</a> 
                    </p>
                    
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Best, 
                    </p>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Team Bioveda
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> -->

