<app-navbar-one></app-navbar-one>

<div class="page-title-area E-Mandi_Page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content E-Mandi_Content">
                    <h2>Buy Agri Product</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>E-Mandi</li>
                    </ul>
                    <img src="assets/img/YI-img/product-banner.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- old section -->
<!-- <section class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="woocommerce-result-count">
                        <p>Showing 1-8 of 14 results</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-6">
                    <div class="woocommerce-topbar-ordering">
                        <select>
                            <option value="1">Default sorting</option>
                            <option value="2">Sort by popularity</option>
                            <option value="0">Sort by average rating</option>
                            <option value="3">Sort by latest</option>
                            <option value="4">Sort by price: low to high</option>
                            <option value="5">Sort by price: high to low</option>
                            <option value="6">Sort by new</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <div class="Product_Filter">
                    <h4>Categories</h4>
                    <div class="faq-accordion">
                        <ul class="accordion">

                            <li class="accordion-item">
                                <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Fertilizers</a>
                                <div class="accordion-content show">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
                                        <label class="form-check-label" for="flexCheckDefault1">
                                            Fertilizers 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2">
                                        <label class="form-check-label" for="flexCheckChecked2">
                                            Fertilizers 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Herbicide</a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
                                        <label class="form-check-label" for="flexCheckDefault3">
                                            Herbicide 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4">
                                        <label class="form-check-label" for="flexCheckChecked4">
                                            Herbicide 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Micronutrients</a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault5">
                                        <label class="form-check-label" for="flexCheckDefault5">
                                            Micronutrients 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked6">
                                        <label class="form-check-label" for="flexCheckChecked6">
                                            Micronutrients 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Insecticides 
                                </a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault7">
                                        <label class="form-check-label" for="flexCheckDefault7">
                                            Insecticides 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked8">
                                        <label class="form-check-label" for="flexCheckChecked8">
                                            Insecticides 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Fungicides </a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault8">
                                        <label class="form-check-label" for="flexCheckDefault8">
                                            Fungicides 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked9">
                                        <label class="form-check-label" for="flexCheckChecked9">
                                            Fungicides 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Micronutrients</a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault9">
                                        <label class="form-check-label" for="flexCheckDefault9">
                                            Micronutrients 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked10">
                                        <label class="form-check-label" for="flexCheckChecked10">
                                            Micronutrients 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    Plant Growth Regulator</a>
                                <div class="accordion-content">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault10">
                                        <label class="form-check-label" for="flexCheckDefault10">
                                            Plant Growth Regulator 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked11">
                                        <label class="form-check-label" for="flexCheckChecked11">
                                            Plant Growth Regulator 2
                                        </label>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-2.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Chicks</a></h3>

                                <div class="price">
                                    <span class="new">$541.50</span>
                                    <span class="old">$652.50</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-3.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Divas</a></h3>

                                <div class="price">
                                    <span class="new">$140.50</span>
                                    <span class="old">$150.50</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="far fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-4.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Smart</a></h3>

                                <div class="price">
                                    <span class="new">$547.00</span>
                                    <span class="old">$600.00</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-2.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Chicks</a></h3>

                                <div class="price">
                                    <span class="new">$541.50</span>
                                    <span class="old">$652.50</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star-half-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-3.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Divas</a></h3>

                                <div class="price">
                                    <span class="new">$140.50</span>
                                    <span class="old">$150.50</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="far fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a routerLink="/products-details" class="d-block"><img
                                        src="assets/img/YI-img/blog-4.jpg" alt="image"></a>

                                <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                        class="flaticon-shopping-cart"></i></a>
                            </div>

                            <div class="product-content">
                                <h3><a routerLink="/products-details">Book Smart</a></h3>

                                <div class="price">
                                    <span class="new">$547.00</span>
                                    <span class="old">$600.00</span>
                                </div>

                                <div class="rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- old section end -->

<section class="shop-area">
    <div class="">
        <div class="shop-area_Title text-center">
            <h1>Buy Agri Product at the best price</h1>
            <p><img src="assets/img/YI-img/reader.png" alt=""> 15,000+ Farmers Believe on us</p>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <div class="Product_Filter">
                    <!-- <h4>Categories</h4> -->
                    <div class="faq-accordion">
                        <ul class="accordion">

                            <li class="accordion-item">
                                <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i>
                                    All Veda Categories</a>
                                <div class="accordion-content show">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
                                        <label class="form-check-label" for="flexCheckDefault1">
                                            Seed
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2">
                                        <label class="form-check-label" for="flexCheckChecked2">
                                            Fertilizers
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3">
                                        <label class="form-check-label" for="flexCheckChecked3">
                                            Insecticides
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4">
                                        <label class="form-check-label" for="flexCheckChecked4">
                                            Pesticides
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked5">
                                        <label class="form-check-label" for="flexCheckChecked5">
                                            Herbicides
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked6">
                                        <label class="form-check-label" for="flexCheckChecked6">
                                            Insect Trap
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked7">
                                        <label class="form-check-label" for="flexCheckChecked7">
                                            Nylon Netting
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked8">
                                        <label class="form-check-label" for="flexCheckChecked8">
                                            Plows
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked9">
                                        <label class="form-check-label" for="flexCheckChecked9">
                                            Tractors
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked10">
                                        <label class="form-check-label" for="flexCheckChecked10">
                                            Poly Houses
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked11">
                                        <label class="form-check-label" for="flexCheckChecked11">
                                            Irrigation Systems
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked12">
                                        <label class="form-check-label" for="flexCheckChecked12">
                                            Bio Pesticides
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked13">
                                        <label class="form-check-label" for="flexCheckChecked13">
                                            Bio Control Agents
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked14">
                                        <label class="form-check-label" for="flexCheckChecked14">
                                            Organic Inputs
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked15">
                                        <label class="form-check-label" for="flexCheckChecked15">
                                            Plant Regulators
                                        </label>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="Product_Filter biovedaApplication">
                    <h5>Download Your BioVeda Application</h5>
                        <div>
                            <img src="assets/img/YI-img/googleplay.png" alt="">
                            <img src="assets/img/YI-img/App-Store.png" alt="">
                        </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="Product_listing_section">
                    <div class="Product_listing_Top">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="Product_listing_section_Title">
                                    <h3>SEEDS</h3>
                                    <p>Vegetable Seeds Shop hundreds of heirlooms, organic, non-GMO vegetable seeds and plants to grow in your garden.</p>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="search_Btn_Box">
                                    <div class="input-group mb-3">
                                        <input type="email" class="form-control" placeholder="Search your agri product"
                                            aria-label="Recipient's username" aria-describedby="button-addon2">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img src="assets/img/YI-img/magnifying-glass.png" alt=""></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-product-box">
                                <div class="product-image">
                                    <a routerLink="/products-details" class="d-block"><img
                                            src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                                    <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                            class="flaticon-shopping-cart"></i></a> -->
                                </div>
    
                                <div class="product-content">
                                    <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                                    <div class="price">
                                        <span class="new">$541.50</span>
                                        <span class="old">$652.50</span>
                                    </div>
    
                                    <!-- <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                    </div> -->

                                    <div class="addtocart_Btn">
                                        <a href="javascript:void(0)">Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="loadMoreBtn">
                                <a href="javascript:void(0)">Load More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!----------- Ready to sell--------------->
<section class="sell_section">
    <div class="container">
        <div class="sell_section_Content">
            <div class="Sell_Inner_Content">
                <div class="sell_section_Img">
                    <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;" src="assets/img/YI-img/readytosell.png" alt="">
                </div>
                <div class="sell_section_Text">
                    <h4 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Ready-to-sell your crop</h4>
                    <p class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2.5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Selling at BioVeda is simple. Just book your appointment at our BioVeda Center</p>
                </div>
                <div class="produceNowBtn">
                    <a class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;" href="javascript:void(0)">SELL AGRI PRODUCE NOW</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!----------- Ready to sell end ------------- -->  

<!-- media coverage/Our Partner -->
<!-- <section class="MediaCoverage_OurPartner">
    <div class="container">
       <div class="row">
        <div class="col-lg-6 borderR">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Media Coverage</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Media Coverage</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Partner</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-item">
                    <div class="OurPartner">
                        <h3 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Our Partner</h3>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-3">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-3">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                        <div class="row mt-4 wow fadeInUp animated" data-wow-duration="2.5s" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                            <div class="col-md-6">
                                <img src="assets/img/YI-img/banner.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <img class="margin_Top" src="assets/img/YI-img/banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>
</section> -->
<!-- media coverage/Our Partner end-->

<!-- partner -->
<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- partner end-->
