<app-navbar-one></app-navbar-one>

<div class="page-title-area join-team_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>Join our team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Join our team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class=" find-job-top find-job wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="find-job">
                    <h3>Find a job with BioVeda</h3>
                    <p class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;
                    ">Search for relevant job openings in Agri-Tech space. Apply to the openings listed here!</p>
                    <input class="form-control" type="search" name="" id="">
                </div>
                <!-- <div class=" row update">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for=""></label>
                            <select class="form-control" name="" id="">
                                <option>wsdfergaerg</option>
                                <option>aetrbghaetb</option>
                                <option>aetrbhaetb</option>
                                <option>wsdfergaerg</option>
                                <option>aetrbghaetb</option>
                                <option>aetrbhaetb</option>
                                <option>wsdfergaerg</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="margin: 0; font-size: 11px;">
                            <label class="switch">
                                <input type="checkbox">
                                <span class="slider"></span>
                            </label> What's App Update
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="find-job-right-img">
                    <img src="../../../../assets/img/YI-img/joinus.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="keep-claim">
    <div class="container">
        <div class="text-center">
            <h3 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 55%;
                    margin: 0 auto;">Keep Claim and Join our BioVeda Team</h3>
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 43%;
                    margin: 0 auto;">We are not a team because we work together, we are a
                team because we respect trust and care for each other</p>
        </div>
    </div>
</section>

<section class="latest-job">
    <div class="container">
        <div class="latest-job-main">
            <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"><span>Latest Jobs</span>
                with BioVeda</h5>
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Kisan Mitra</h5>
                        <p>Badaun, Sambhal, Farrukhabad & Kasganj District, India</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
            <!-- <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Distributor</h5>
                        <p>Badaun & Sambhal District, India</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div> -->
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Agri Input Category Manager</h5>
                        <p>Delhi NCR</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Training Manager</h5>
                        <p>Badaun & Sambhal District, India</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>ASMs</h5>
                        <p>Badaun, Sambhal, Farrukhabad & Kasganj District, India</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Accounts Lead</h5>
                        <p>Gurgaon, India Sale</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
            <div class="apply-box">
                <div class="flax-box1">
                    <img src="assets/img/YI-img/BoiveadLogo.png" alt="" class="BioLogo">
                    <div class="flax-box1-cont">
                        <h5>Supply chain Lead</h5>
                        <p>Badaun & Sambhal</p>
                    </div>
                </div>
                <div class="apply-btn">
                    <a class="btn btn-default palin-btn" href="">Share job</a>
                    <a class="btn btn-default palin-btn2" href="mailto:mahua@biovedaagroventures.com">Apply job</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>