import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-agency-home',
  templateUrl: './seo-agency-home.component.html',
  styleUrls: ['./seo-agency-home.component.scss']
})
export class SeoAgencyHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
