<app-navbar-one></app-navbar-one>

<div class="page-title-area E-Mandi_Page Sell_Product_Page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content E-Mandi_Content Sell_Product_Content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="Sell_Product_Left">
                                <ul>
                                    <li><a routerLink="/">Home</a></li>
                                    <li>Buy Agri Product</li>
                                    <li>Sell Agri Product</li>
                                </ul>
                                <h6>Sell Agri Produce</h6>
                                <h2>Get BioVeda Mobile Application</h2>
                                <p>Hight Quality Product | Sell- Agri Produce
                                    Buy Agri Product | Veda Support</p>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                                        placeholder="Enter your 10 digit mobile number">
                                    <button type="button" class="input-group-text subscribeBtn">
                                        <img src="assets/img/YI-img/paper-plane.png" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img src="assets/img/Bioveda-img/sell_right.png" alt="">
                        </div>
                    </div>
                    <div class="Banner_Slider">
                        <div class="d-flex justify-content-between" style="position: relative; top: 85px;">
                            <a class="left" href="">←</a>
                            <a class="right" href="">→</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                        style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">How we work hassel
                        free Selling with us</h2>
                    <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 50%;
                    margin: 0 auto;">Just follow this 4 step</p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="Content_Arrow">
                    <div class="Step_1 text-center">
                        <div class="Step_1_box"></div>
                        <h5>CROP</h5>
                        <p>Select your crop Like potato, Pees etc</p>
                    </div>
                    <img src="assets/img/YI-img/right-arrow-new.png" alt="">
                </div>
            </div>
            <div class="col-md-3">
                <div class="Content_Arrow">
                    <div class="Step_1 text-center">
                        <div class="Step_1_box"></div>
                        <h5>Quantity</h5>
                        <p>Select or enter the quantity you need</p>
                    </div>
                    <img src="assets/img/YI-img/right-arrow-new.png" alt="">
                </div>
            </div>
            <div class="col-md-3">
                <div class="Content_Arrow">
                    <div class="Step_1 text-center">
                        <div class="Step_1_box"></div>
                        <h5>Parameter</h5>
                        <p>Fill some parameter field to generate a better price list</p>
                    </div>
                    <img src="assets/img/YI-img/right-arrow-new.png" alt="">
                </div>
            </div>
            <div class="col-md-3">
                <div class="Content_Arrow">
                    <div class="Step_1 text-center">
                        <div class="Step_1_box"></div>
                        <h5>View Rate</h5>
                        <p>Almost Done! Now you free to generate a Price Rate</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">
                <div class="populer-crop">
                    <div class="">
                        <h2 class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Popular Crop
                            as
                            Our Veda Recommendation</h2>
                        <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; 
                                           ">We have many thing why you gave your important time to us</p>
                    </div>
                    <div class=" view-crop">
                        <a class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;" href="">View All Crop</a>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 1</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 2</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 3</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 4</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 5</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 6</h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 7</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 8</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 9</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 10</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 11</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 12</h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 13</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 14</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 15</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 16</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 17</h6>
                </div>
            </div>
            <div class="col-md-2">
                <div class="Popular_Crop_Boxs">
                    <div class="Popular_Crop_Box"></div>
                    <h6>Crop Name 18</h6>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-12">
                <div class="populer-crop mt-5">
                    <div class="Selling-at">
                        <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; ">Selling at BioVeda is simple. Just book your appointment at our BioVeda Center</p>
                    </div>
                    <div class=" appointment">
                        <a href="">BOOK YOUR APPOINTMENT</a>
                    </div>
        
                </div>
            </div>
        </div>
    </div>
</section>



<!-- testimonial_Section -->
<section class="testimonial_Section wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay=".5s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <div class="container">
            <div class="">
                <h2>Testimonials</h2>
                <p>What our customer say about Bioveda</p>
            </div>
        </div>
        <div class="saying-slides owl-carousel owl-theme">
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
            <div class="tesi-item">
                <div class="testi_Img">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="assets/img/YI-img/left-quote.png" alt="">
                        <img src="assets/img/YI-img/testimonial-1.png" alt="">
                    </div>
                    <h5>Rinku Sonker</h5>
                    <h6>Onion Buyer</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro alias odit maiores molestiae
                        unde, consectetur molestias.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- testimonial_Section end -->

<!-- partner -->
<!-- <section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div>
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
    
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="#" target="_blank" class="d-block"><img src="assets/img/YI-img/3.webp" alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- partner end-->