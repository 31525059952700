import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saas-home',
  templateUrl: './saas-home.component.html',
  styleUrls: ['./saas-home.component.scss']
})
export class SaasHomeComponent implements OnInit {

  isShow = true;
 
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  

  constructor() { }

  ngOnInit(): void {
  }

}
