<app-navbar-one></app-navbar-one>
<div class="page-title-area buy_product_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>download app</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Download App</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row suppliers_section">
    <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="banner-Slider owl-carousel owl-theme wow fadeInUp animated" data-wow-duration="1.5s"
            data-wow-delay=".2s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; margin-top: 3rem;">
            <div class="banner-item">
                <img class="why_right_Img" style="width: 85%; float: right;" src="assets/img/YI-img/newsletter.png"
                    alt="">
            </div>

            <div class="banner-item">
                <img class="why_right_Img" style="width: 85%; float: right;" src="assets/img/YI-img/newsletter2.png"
                    alt="">
            </div>

            <div class="banner-item">
                <img class="why_right_Img" style="width: 85%; float: right;" src="assets/img/YI-img/newsletter3.png"
                    alt="">
            </div>
            <div class="banner-item">
                <img class="why_right_Img" style="width: 85%; float: right;" src="assets/img/YI-img/newsletter4.png"
                    alt="">
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="footerText wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
            <h2>Access best Agri input supplies from a range of suppliers</h2>
        </div>
        <div class="newsletter suppliersCon wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
            <p>Please 10-digit mobile number to get mobile application link</p>
            <div class="input-group mb-3">
                <span class="input-group-text">+91</span>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                    placeholder="Enter your number here">
                <button type="button" class="input-group-text subscribeBtn"><img src="assets/img/YI-img/paper-plane.png"
                        alt=""></button>
            </div>
        </div>
        <div class="app_Btn app_button">
            <h5 class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Download Your
                Bioveda Application</h5>
            <div>
                <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1.5s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                    src="assets/img/YI-img/googleplay.png" alt="">
                <img class="wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="2s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                    src="assets/img/YI-img/App-Store.png" alt="">
            </div>
        </div>
    </div>
</div>