<app-navbar-one></app-navbar-one>

<div class="page-title-area join-team_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2> our team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li> Our Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
<div class="TeamSec activity-Slider owl-carousel owl-theme mt-5">
    <div class="banner-item">
        <div class="TeamBlock margin_Top">
            <div class="content">
                <a>
                    <div class="content-overlay"></div>
                    <img class="content-image" src="assets/img/YI-img/team.png">
                    <div class="text_bottom">
                        <h2>FOUNDER</h2>
                    </div>
                    <div class="content-details fadeIn-bottom">
                        <div class="card_inner_text">
                            <div class="back_thum">
                                <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                <img class="teamshortImg" src="assets/img/YI-img/team.png" alt="">
                            </div>
                            <h5>Kaushik Dasgupta</h5>
                            <span>BUSINESS DEV.SUPPLY CHAIN SALES</span>
                            <div class="sub_cont">
                                <p>SALES DISTRIBUTION SUPPLY CHAIN 20+ EXP.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="banner-item">
        <div class="TeamBlock margin_Top">
            <div class="content">
                <a>
                    <div class="content-overlay"></div>
                    <img class="content-image" src="assets/img/YI-img/team2.png">
                    <div class="text_bottom">
                        <h2>FOUNDER</h2>
                    </div>
                    <div class="content-details fadeIn-bottom">
                        <div class="card_inner_text">
                            <div class="back_thum">
                                <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                <img src="assets/img/YI-img/team2.png" alt="">
                            </div>
                            <h5>Mahua Hazra</h5>
                            <!-- <span>MARKETING TRAININING MFI/NBFC ENAGEMENT</span> -->
                            <div class="sub_cont">
                                <p>Agent Management Marketing Channel Management and Training</p>
                                <!-- <p>MARKETING BFSI FINANCE (Client Servicing) 22+ Exp.</p> -->
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <!-- <div class="banner-item">
                    <div class="TeamBlock ">
                        <div class="content">
                            <a>
                                <div class="content-overlay"></div>
                                <img class="content-image" src="assets/img/YI-img/team3.png">
                                <div class="text_bottom">
                                    <h2>CORE TEAM</h2>
                                </div>
                                <div class="content-details fadeIn-bottom">
                                    <div class="card_inner_text">
                                        <div class="back_thum">
                                            <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                            <img src="assets/img/YI-img/team3.png" alt="">
                                        </div>
                                        <h5>Dr. Alok Srivastava</h5>
                                        <span>CORE PRODUCT DEV.PROJECTS Consultant - Word Bank, JICA</span>
                                        <div class="sub_cont">
                                            <p>AGRI F$V DOMAIN EXPERT (Pan India/Global)30+ Exp.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div> -->
    <!-- <div class="banner-item">
                    <div class="TeamBlock margin_Top">
                        <div class="content">
                            <a>
                                <div class="content-overlay"></div>
                                <img class="content-image" src="assets/img/YI-img/team4.png">
                                <div class="text_bottom">
                                    <h2>CORE TEAM</h2>
                                </div>
                                <div class="content-details fadeIn-bottom">
                                    <div class="card_inner_text">
                                        <div class="back_thum">
                                            <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                            <img src="assets/img/YI-img/team4.png" alt="">
                                        </div>
                                        <h5>Sunil Sihag</h5>
                                        <span>AGRI INPUT & ADVISORY MANAGEMENT</span>
                                        <div class="sub_cont">
                                            <p>AGRI INPUT AGRI OUTPUT EXPERT 23+ Exp.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div> -->
    <div class="banner-item">
        <div class="TeamBlock margin_Top">
            <div class="content">
                <a>
                    <div class="content-overlay"></div>
                    <img class="content-image" src="assets/img/YI-img/harshsir-photo.png">
                    <div class="text_bottom">
                        <h2>TECH. PARTNER</h2>
                    </div>
                    <div class="content-details fadeIn-bottom">
                        <div class="card_inner_text">
                            <div class="back_thum">
                                <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                <img src="assets/img/YI-img/harshsir-photo.png" alt="">
                            </div>
                            <h5>Harsh Kumar</h5>
                            <span>HEAD - TECHNOLOGY DEVELOPMENT</span>
                            <div class="sub_cont">
                                <p>TECHNOLOGY DEVELOPMENT EXPERT 15+ Exp.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="banner-item">
        <div class="TeamBlock margin_Top">
            <div class="content">
                <a>
                    <div class="content-overlay"></div>
                    <img src="assets/img/YI-img/logo_img.png" alt="">
                    <div class="text_bottom">
                        <h2>INVESTOR</h2>
                    </div>
                    <div class="content-details fadeIn-bottom">
                        <div class="card_inner_text">
                            <div class="back_thum">
                                <img class="teamplacelogo" src="assets/img/YI-img/teamplacelogo.png" alt="">
                                <img src="assets/img/YI-img/logo_img.png" alt="">
                            </div>
                            <!-- <h5>Harsh Kumar</h5> -->
                            <!-- <span>MENTOR & GUIDE FOR BUSINESS MANAGEMENT</span> -->
                            <div class="sub_cont">
                                <p>MENTOR & GUIDE FOR BUSINESS MANAGEMENT</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <!-- <div class="banner-item">
                <div class="TeamBlock margin_Top">
                    <div class="content">
                        <div class="bg_thum">
                            <img src="assets/img/YI-img/logo_img.png" alt=""
                                style="width: -webkit-fill-available; height: 18rem; border-radius: 10px;">
                            <div class="text_bottom">
                                <h2>INVESTOR</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
</div>
</div>


<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>