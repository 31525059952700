<div class="topBar">
  <div class="">
    <div class="topSec">
      <div class="DateTime">
        <!-- <p><img src="assets/img/YI-img/clock.png" alt=""> 12:34:45</p> -->
        <p class="calender"><img src="assets/img/YI-img/calendar.png" alt=""> 20 November 2024</p>
      </div>
      <div class="top">
        <a href="javascript:void(0)" class="skipContent location"><img src="assets/img/YI-img/pin.png" alt=""> Lucknow
          226001</a>
        <a href="https://play.google.com/store/apps/details?id=com.troology.bioveda&hl=en" target="_blank" class="skipContent">Are you a Bioveda Member? Register / Login</a>
        <a href="javascript:void(0)"><img class="reader" src="assets/img/YI-img/reader.png" alt=""></a>
        <select class="lang selectpicker form-control">
          <option value="orignal" id="btn-orig">A</option>
          <option value="increase" id="btn-increase">A+</option>
          <option value="decrease" id="btn-decrease">A-</option>
        </select>
        <div class="ml themeColor">
          <button id="colorDark" type="button" style="background-color: #404040;
                    border: none;
                    color: #fff; padding: 10px;">A</button>
          <button id="colorWhite" type="button" style="background-color:rgb(223 223 223); border:none; padding: 10px;"
            onclick="white()">A</button>
        </div>
        <select class="lang selectpicker form-control">
          <option value="English">English</option>
          <option value="Hindi">Hindi</option>
          <option value="Tamil">Tamil</option>
          <option value="Gujarati">Gujarati</option>
        </select>
        <div>
          <img class="flagImg" src="assets/img/YI-img/flag.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="navbar-area Menu_section">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">
        <img class="BioLogo" src="assets/img/YI-img/BoiveadLogo.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <img class="navbar-toggler-icon" src="assets/img/YI-img/menu-toggle.png" alt="">
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <!-- <a class="nav-link" routerlink="/about">about boiveda</a> -->
            <a routerLink="/about" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">about us</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">sell agri produce</a> -->
            <a routerLink="/sell-your-produce" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">sell agri produce</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">sell your produce</a> -->
            <a routerLink="/buy-agri-Product" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">buy agri product</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">avail loan</a> -->
            <a routerLink="/avail-loan" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">BV quick</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">our team</a> -->
            <a routerLink="/team" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">our team</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">e-mandi</a> -->
            <a routerLink="/e-mandi" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">e-mandi</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">download app</a> -->
            <a routerLink="/download-app" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">download app</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">career</a> -->
            <a routerLink="/career" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">career</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="javacsript:void(0)">helpline</a> -->
            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
          </li>
        </ul>
        <div class="newsticker js-newsticker">
          <ul class="js-frame">
            <li class="js-item">
              <div class="MandiRangeDiv d-flex align-items-center">
                <div class="productImg">
                  <img src="assets/img/YI-img/Potato.png" alt="">
                </div>
                <div class="MandiRange">
                  <div>
                    <h6>Potato <span>Lucknow Mandi</span></h6>
                  </div>
                  <div class="MandiPrice">
                    <h6><i class="fa fa-rupee-sign"></i>400 <span><img src="assets/img/YI-img/dropUp.png" alt="">
                        28%</span></h6>
                  </div>

                </div>
              </div>
            </li>
            <li class="js-item">
              <div class="MandiRangeDiv d-flex align-items-center">
                <div class="productImg">
                  <img src="assets/img/YI-img/Potato.png" alt="">
                </div>
                <div class="MandiRange">
                  <div>
                    <h6>Potato <span>Lucknow Mandi</span></h6>
                  </div>
                  <div class="MandiPrice">
                    <h6><i class="fa fa-rupee-sign"></i>400 <span><img src="assets/img/YI-img/dropUp.png" alt="">
                        28%</span></h6>
                  </div>

                </div>
              </div>
            </li>
            <li class="js-item">
              <div class="MandiRangeDiv d-flex align-items-center">
                <div class="productImg">
                  <img src="assets/img/YI-img/Potato.png" alt="">
                </div>
                <div class="MandiRange">
                  <div>
                    <h6>Potato <span>Lucknow Mandi</span></h6>
                  </div>
                  <div class="MandiPrice">
                    <h6><i class="fa fa-rupee-sign"></i>400 <span><img src="assets/img/YI-img/dropUp.png" alt="">
                        28%</span></h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

</div>