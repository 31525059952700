<app-navbar-one></app-navbar-one>

<div class="page-title-area about_Section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content about_Section_Content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="about-bg">
        <img style="width: 100%; margin-top: -54px;" src="../../../../assets/img/YI-img/about-bg.png" alt="">
    </div>
</section>

<section class="indian-farmers">
    <div class="container">
        <div class="text-center">
            <span class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">What is the biggest
                problem of Indian farmers ?</span>
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 55%;
                    margin: 0 auto;">A marginal Indian farmer loses 60-70% of his capital every year through three
                interconnected aspects of farming</p>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="indian-farmers-box">
                    <div class="farmer-box-img">
                        <img class="bg-img" src="../../../../assets/img/YI-img/bg1.png" alt="">
                        <img class="ab-img" src="../../../../assets/img/YI-img/wt1.png" alt="">
                    </div>
                    <div class="box-cont">
                        <h6>High rate of interest</h6>
                        <p>3-4% monthly annualized to 40-50%</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="indian-farmers-box">
                    <div class="farmer-box-img">
                        <img class="bg-img" src="../../../../assets/img/YI-img/bg2.png" alt="">
                        <img class="ab-img" src="../../../../assets/img/YI-img/wt2.png" alt="">
                    </div>
                    <div class="box-cont">
                        <h6>High cost of inputs</h6>
                        <p>15-20% of capital</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="indian-farmers-box">
                    <div class="farmer-box-img">
                        <img class="bg-img" src="../../../../assets/img/YI-img/bg3.png" alt="">
                        <img class="ab-img" src="../../../../assets/img/YI-img/wt3.png" alt="">
                    </div>
                    <div class="box-cont">
                        <h6>Poor output prices</h6>
                        <p>15-20% on output</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="no-wonder">
    <div class="container">
        <div class="text-center">
            <p class="mainPara wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; width: 55%;
                    margin: 0 auto;">No wonder, all the govt subsidies put together, the Marginal farmer remains stuck
                in this vicious cycle of poverty.</p>
        </div>
    </div>
</section>

<section class="solution">
    <div class="container">
        <div class="solution-main text-center">
            <span class="mainHeading wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="0.5s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Solution bahut simple
                hai</span>

            <div class="row" style="align-items: center;">
                <div class="col-md-7">
                    <div class="solution-content">
                        <p class=" wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Introducing
                            the FIRST integrated online platform for Farmers - Offering a range of Agri- inputs at lower
                            prices, Agri-
                            outputs at better prices leveraging the principle of aggregation and providing Credit at
                            affordable rates.</p>
                        <p class=" wow fadeInUp animated" data-wow-duration="1.5s" data-wow-delay="1s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Bioveda Agro
                            Ventures is an Agri-tech start-up based out of Gurgaon working in UP. The start-up
                            deals in
                            selling Agri Inputs & also engages in buying Non-Perishable Agri-Outputs.</p>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="solution-img">
                        <img src="../../../../assets/img/YI-img/solution.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section wow fadeInUp animated" data-wow-duration="2s" data-wow-delay="0.3s"
    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
    <div class="">
        <!-- <div class="text-center mb-3">
            <h1>Our Partner</h1>
            <img class="mb-5" src="assets/img/YI-img/line.png" alt="">
        </div> -->
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/of-business-ml1.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/zepto-ml2.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/g-ml3.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/roquette-ml4.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/Zomato-ml5.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/swiggy-ml6.png"
                            alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a href="javascript:void(0)" class="d-block"><img src="assets/img/YI-img/ia-ml7.png"
                            alt="partner"></a>
                </div>
            </div>
        </div>
    </div>
</section>