<app-navbar-one></app-navbar-one>

<div class="page-title-area E-Mandi_Page product_Detail_Page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content E-Mandi_Content">
                    <h2>Buy Agri Product</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>E-Mandi</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="shop-area Product_Detail_Sec">
        <div class="row">
            <div class="col-lg-6">
                <div class="Product_Detail_Sec_Left">
                    <img src="assets/img/YI-img/blog-2.jpg" alt="" class="Product_Img">
                    <div class="Product-Detail-Content">
                        <h4>Product Details</h4>
                        <h6><img src="assets/img/YI-img/minus.png" alt="" class="">Key Features</h6>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint consectetur voluptates placeat quod nobis nam sapiente saepe commodi facilis, nostrum laudantium minus modi, impedit dolorem tempora vel.</p>
                        <h6><img src="assets/img/YI-img/minus.png" alt="" class="">Key Features</h6>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint consectetur voluptates placeat quod nobis nam sapiente saepe commodi facilis, nostrum laudantium minus modi, impedit dolorem tempora vel.</p>
                        <a href="javascript:void(0)">See More +</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="Product_Detail_Sec_Left Product_Detail_Sec_Right">
                    <span class="offerTag">33% off</span>
                    <h3>All Rounder+- 500ML + Trigun</h3>
                    <p class="mainPara">The mung bean (Vigna radiata), alternatively known as the green gram, mash, mung, monggo,.....</p>
                    <h2><i class="fa fa-rupee-sign"></i>500 <del>750</del></h2>
                    <div class="Add-to-cart">
                        <a href="javascript:void(0)">Add to cart</a>
                    </div>
                </div>
            </div>
        </div>
</section>

<section>
    <div class="container">
        <h4 class="mb-5">You might also like</h4>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img
                                src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                        <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                class="flaticon-shopping-cart"></i></a> -->
                    </div>
    
                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                        <div class="price">
                            <span class="new">$541.50</span>
                            <span class="old">$652.50</span>
                        </div>
    
                        <!-- <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div> -->
    
                        <div class="addtocart_Btn">
                            <a href="javascript:void(0)">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img
                                src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                        <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                class="flaticon-shopping-cart"></i></a> -->
                    </div>
    
                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                        <div class="price">
                            <span class="new">$541.50</span>
                            <span class="old">$652.50</span>
                        </div>
    
                        <!-- <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div> -->
    
                        <div class="addtocart_Btn">
                            <a href="javascript:void(0)">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img
                                src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                        <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                class="flaticon-shopping-cart"></i></a> -->
                    </div>
    
                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                        <div class="price">
                            <span class="new">$541.50</span>
                            <span class="old">$652.50</span>
                        </div>
    
                        <!-- <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div> -->
    
                        <div class="addtocart_Btn">
                            <a href="javascript:void(0)">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-product-box">
                    <div class="product-image">
                        <a routerLink="/products-details" class="d-block"><img
                                src="assets/img/YI-img/blog-2.jpg" alt="image"></a>
    
                        <!-- <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                class="flaticon-shopping-cart"></i></a> -->
                    </div>
    
                    <div class="product-content">
                        <h3><a routerLink="/products-details">Book Chicks</a></h3>
    
                        <div class="price">
                            <span class="new">$541.50</span>
                            <span class="old">$652.50</span>
                        </div>
    
                        <!-- <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div> -->
    
                        <div class="addtocart_Btn">
                            <a href="javascript:void(0)">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
